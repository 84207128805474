import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Helpers from "../config/Helpers";
import ChatGPTFormatter from "./ChatGPTFormatter";
import { FiRefreshCw } from 'react-icons/fi';
import './Chatbox.css';

const Chatbox = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      avatar: "assets/images/robo.jpg",
      name: "Chatbot",
      text: "Hello! How can I assist you today?",
      time: "10:00",
      isBot: true,
    },
  ]);

  const [newMessage, setNewMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [typingMessage, setTypingMessage] = useState("");
  const [filterType, setFilterType] = useState("Query");
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState("");
  const chatEndRef = useRef(null);

  const sendMessage = async (messageText = null) => {
    if ((filterType === "Query" && newMessage.trim() === "") || (filterType === "Keyword" && keywords.length === 0) || isSending) return;

    const messageContent = messageText || (filterType === "Query" ? newMessage : keywords.join(", "));

    const userMessage = {
      id: messages.length + 1,
      avatar: "assets/images/user.jpg",
      name: "You",
      text: messageContent,
      time: new Date().toLocaleTimeString(),
      isBot: false,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setNewMessage("");
    setKeywords([]);
    setTypingMessage("Chatbot is typing...");

    try {
      setIsSending(true);
      const apiUrl = `${Helpers.apiUrl}api/query/process-query/`;
      const response = await axios.post(apiUrl, {
        query: messageContent,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const botResponse = response.data.response || "Sorry, I didn't understand that.";
      generateTypingEffect(botResponse);
    } catch (error) {
      console.error("Failed to fetch chat response:", error);
    } finally {
      setIsSending(false);
    }
  };

  const generateTypingEffect = (text) => {
    let index = 0;
    let tempMessage = "";

    const interval = setInterval(() => {
      if (index < text.length) {
        tempMessage += text.charAt(index);
        setTypingMessage(tempMessage);
        index++;
      } else {
        clearInterval(interval);
        const botMessage = {
          id: messages.length + 2,
          avatar: "assets/images/robo.jpg",
          name: "Chatbot",
          text: tempMessage,
          time: new Date().toLocaleTimeString(),
          isBot: true,
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        setTypingMessage("");
      }
    }, 50);
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSendClick = () => {
    sendMessage();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleKeywordChange = (e) => {
    setKeywordInput(e.target.value);
  };

  const handleAddKeyword = () => {
    if (keywordInput.trim() !== "") {
      setKeywords((prevKeywords) => [...prevKeywords, keywordInput.trim()]);
      setKeywordInput("");
    }
  };

  const handleRemoveKeyword = (index) => {
    setKeywords((prevKeywords) => prevKeywords.filter((_, i) => i !== index));
  };

  const handleRegenerateClick = async (originalMessage) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.text !== originalMessage));
    await sendMessage(originalMessage);
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, typingMessage]);

  return (
    <div className="user-chat w-100 overflow-hidden d-flex flex-column" style={{ height: "100vh" }}>
      <div className="d-lg-flex flex-grow-1">
        <div className="w-100 overflow-hidden position-relative d-flex flex-column">
          <div className="p-3 p-lg-4 border-bottom user-chat-topbar">
            <div className="row align-items-center">
              <div className="col-sm-4 col-8">
                <div className="d-flex align-items-center">
                  <div className="me-3 ms-0">
                    <img
                      src="assets/images/robo.jpg"
                      className="rounded-circle avatar-xs"
                      alt="Chatbot Avatar"
                    />
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-0 text-truncate">
                      MyGPT
                      <i className="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 col-4 text-end">
                <div className="filter-container d-flex align-items-center">
                  <label className="me-2" htmlFor="filterType">Filter:</label>
                  <select className="form-select form-select-sm" id="filterType" value={filterType} onChange={handleFilterChange}>
                    <option value="Query">Query</option>
                    <option value="Keyword">Keyword</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="chat-conversation p-3 p-lg-4 flex-grow-1" style={{ overflowY: "auto" }}>
            <ul className="list-unstyled mb-0">
              {messages.map((message) => (
                <li key={message.id} className={message.isBot ? "left" : "right"}>
                  <div className="conversation-list">
                    <div className="chat-avatar">
                      <img src={message.avatar} alt="Avatar" />
                    </div>
                    <div className="user-chat-content">
                      <div className="ctext-wrap">
                        <div className="ctext-wrap-content">
                          <ChatGPTFormatter response={message.text} />
                          <p className="chat-time mb-0">
                            <FiRefreshCw className="reload-icon" onClick={() => handleRegenerateClick(message.text)} />
                            <i className="ri-time-line align-middle"></i>
                            <span className="align-middle">{message.time}</span>
                          </p>
                        </div>
                      </div>
                      <div className="conversation-name">{message.name}</div>
                    </div>
                  </div>
                </li>
              ))}
              {typingMessage && (
                <li className="left">
                  <div className="conversation-list">
                    <div className="chat-avatar">
                      <img src="assets/images/robo.jpg" alt="Avatar" />
                    </div>
                    <div className="user-chat-content">
                      <div className="ctext-wrap">
                        <div className="ctext-wrap-content">
                          <ChatGPTFormatter response={typingMessage} writing={true} />
                        </div>
                      </div>
                      <div className="conversation-name">MyGPT</div>
                    </div>
                  </div>
                </li>
              )}
              <div ref={chatEndRef} />
            </ul>
          </div>

          <div className="chat-input-section p-3 p-lg-4 border-top mb-0">
            <div className="row g-0">
              <div className="col">
                <div className="input-group">
                  {filterType === "Keyword" && (
                    <>
                      {keywords.map((keyword, index) => (
                        <span key={index} className="badge bg-primary me-2">
                          {keyword}
                          <button
                            type="button"
                            className="btn-close btn-close-white ms-2"
                            aria-label="Close"
                            onClick={() => handleRemoveKeyword(index)}
                          ></button>
                        </span>
                      ))}
                      <input
                        type="text"
                        className="form-control form-control-lg bg-light border-light"
                        placeholder="Add a keyword..."
                        value={keywordInput}
                        onChange={handleKeywordChange}
                        onKeyDown={(e) => e.key === "Enter" && handleAddKeyword()}
                      />
                      <button
                        className="btn btn-primary btn-outline-secondary text-white"
                        type="button"
                        onClick={handleAddKeyword}
                      >
                        Add
                      </button>
                    </>
                  )}
                  {filterType === "Query" && (
                    <input
                      type="text"
                      className="form-control form-control-lg bg-light border-light"
                      placeholder="Type your message..."
                      value={newMessage}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                </div>
              </div>
              <div className="col-auto">
                <div className="chat-input-links ms-2">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item"></li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-link text-decoration-none font-size-16"
                      >
                        <i className="ri-attachment-line"></i>
                      </button>
                    </li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary font-size-16"
                        onClick={handleSendClick}
                      >
                        <i className="ri-send-plane-2-fill"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
