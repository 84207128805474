import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Chatbot from "./Chatbot";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Check if user is logged in
    const isLoggedIn = localStorage.getItem("session_token");
    const publicPaths = ["/login", "/register"];

    if (!isLoggedIn && !publicPaths.includes(location.pathname)) {
      window.location.replace("/login");
    }

    if (isLoggedIn && (location.pathname === "/login" || location.pathname === "/register")) {
      window.location.replace("/chatbot");
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/chatbot" element={<Chatbot />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
